import { $get, $post, $postWithAuth } from "@/utils/request";
import { BaseResponse, BaseAPI } from "./api";
import { $myWs } from "@/utils/request-ws";

export const $getSymbols = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetSymbols, parmas);
  return r;
};

export const $getIntervals = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetIntervals, parmas);
  return r;
};
export const $getDictionary = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetDictionary, parmas);
  return r;
};
export const $getExchangeInfo = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetExchangeInfo, parmas);
  return r;
};
export const $getSymbolsAI = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetSymbolsAI, parmas);
  return r;
};

export const $getSymbolsMargin = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetSymbolsMargin, parmas);
  return r;
};

export const $getSymbolsFundingRateNewestList = async (parmas: any) => {
  const r: BaseResponse = await $get(
    BaseAPI.GetSymbolsFundingRateNewestList,
    parmas
  );
  return r;
};

export const $getSymbolsFundingRateHistoryList = async (parmas: any) => {
  const r: BaseResponse = await $get(
    BaseAPI.GetSymbolsFundingRateHistoryList,
    parmas
  );
  return r;
};

export const $getLeverageBrackets = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetLeverageBrackets, parmas);
  return r;
};
export const $getAISelectSymbol = async (params: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetAISelectSymbol, params);
  return r;
};

export const $postContactUs = async (params: any) => {
  const r: BaseResponse = await $post(BaseAPI.PostContactUs, params);
  return r;
};

export const $postApplicationCareer = async (params: any) => {
  const r: BaseResponse = await $post(BaseAPI.PostApplicationCareer, params);
  return r;
};
export const $getSymbolsAIWs = (
  params: any,
  onopen: () => void,
  onmessage: (evt: any) => void,
  onerror: (evt: any) => void,
  onclose: () => void
) => {
  return $myWs(
    BaseAPI.GetSymbolsAIWs,
    params,
    onopen,
    onmessage,
    onerror,
    onclose
  );
};

export const $getSymbolsAstockName = async (parmas: any) => {
  const r: BaseResponse = await $get(BaseAPI.GetSymbolsAstockName, parmas);
  return r;
};
